
import Vue from "vue";
import Component from "vue-class-component";
import HeaderV2UserAvatarAtom from "@/components/header-v2/atoms/HeaderV2UserAvatarAtom.vue";
import HeaderV2UserTitleAtom from "@/components/header-v2/atoms/HeaderV2UserTitleAtom.vue";
import HeaderV2CogAtom from "@/components/header-v2/atoms/HeaderV2CogAtom.vue";
import SidebarMenuItem from "@/components/sidebar/menuItem/SidebarMenuItem.vue";
import {
  faBuilding,
  faFileInvoice,
  faLaptopCode,
  faLifeRing,
  faClipboardList,
  faSignOutAlt,
  IconDefinition,
  faUsers,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import HeaderV2UserDdItem from "@/components/header-v2/atoms/HeaderV2UserDdItem.vue";
import { StoreActionTypes } from "@/store/types";
import { isUserCanAccessChildren } from "@/services/roles/helpers";
import { isUserHasPermission } from "@/services/roles";
import { UserPermissions } from "@/services/roles/permissionsEnum";

const IHeaderV2UserMolecule = Vue.extend({
  name: "HeaderV2UserMolecule",
});

@Component({
  computed: {
    UserPermissions() {
      return UserPermissions;
    },
  },
  methods: { isUserHasPermission, isUserCanAccessChildren },
  components: {
    HeaderV2UserDdItem,
    SidebarMenuItem,
    HeaderV2CogAtom,
    HeaderV2UserTitleAtom,
    HeaderV2UserAvatarAtom,
  },
})
export default class HeaderV2UserMolecule extends IHeaderV2UserMolecule {
  private componentClass = "HeaderV2UserMolecule";

  private usersIcon = faUsers;
  private companyIcon: IconDefinition = faBuilding;
  private exitIcon: IconDefinition = faSignOutAlt;
  private tutorialVideoIcon: IconDefinition = faLaptopCode;
  private subscriptionIcon: IconDefinition = faFileInvoice;
  private changelogIcon: IconDefinition = faClipboardList;
  private faqIcon: IconDefinition = faLifeRing;
  userIcon: IconDefinition = faUser;

  get authData() {
    return this.$store.getters.getAuthentificationProfile;
  }

  get userTitle(): string {
    const fName: string = this.authData ? this.authData.firstname : "";
    let lName: string = this.authData ? this.authData.lastname : "";
    if (lName.toLowerCase() == "фамилия") {
      lName = "";
    }

    return `${fName} ${lName}`;
  }

  //region Dropdown actions

  handleLogout() {
    this.$store.dispatch(StoreActionTypes.LOG_OUT);
    this.$router.push("/login");
  }

  openChangelog() {
    this.$router.push("/changelog");
  }

  showTutorialVideo() {
    this.$store.dispatch(
      StoreActionTypes.SET_AUTHENTIFICATION_SHOW_TUTORIAL_VIDEO,
      true
    );
  }

  openFaq() {
    window.open(
      "https://docs.google.com/document/d/1yhVtv5JrenPS1alyPmijp8xKYfDWpaniTl8X8ToPeZg/edit",
      "_blank"
    );
  }

  //endregion
}
