
import Vue from "vue";
import Component from "vue-class-component";
import EIcon from "@/components/ewa/general/Icon.vue";

const IHeaderV2LogoAtom = Vue.extend({
  props: {},
});

@Component({
  components: {
    EIcon,
  },
})
export default class HeaderV2LogoAtom extends IHeaderV2LogoAtom {
  public componentClass = "HeaderV2LogoAtom";

  get currentRoute() {
    return this.$route.path;
  }

  public logos = {
    "/ewa": "EWA",
  };
}
