
import Vue from "vue";
import Component from "vue-class-component";
import ClickOutside from "vue-click-outside";

const HeaderV2ProjectListAtomProps = Vue.extend({
  directives: {
    ClickOutside,
  },
});

@Component({})
export default class HeaderV2ProjectListAtom extends HeaderV2ProjectListAtomProps {
  private componentClass = "HeaderV2ProjectListAtom";
  private projects = [
    {
      name: "",
      logo: {
        name: "OK",
        height: "24px",
        width: "107px",
      },
      uri: this.env ? this.env.VUE_APP_OK_URL : "https://app.ectem.ru/",
    },
    {
      name: "",
      logo: {
        name: "EWA",
        height: "25px",
        width: "119px",
      },
      uri: this.env ? this.env.VUE_APP_EWA_URL : "https://app.ectem.ru/ewa",
    },
  ];

  get env() {
    //console.log(process.env)
    return process.env;
  }

  hide() {
    this.$emit("hide");
  }

  goToUri(uri) {
    window.open(uri);
  }
}
