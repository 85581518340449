
import Vue from "vue";
import Component from "vue-class-component";
import HeaderV2UserMolecule from "@/components/header-v2/molecules/HeaderV2UserMolecule.vue";
import HeaderV2ControlMolecule from "@/components/header-v2/molecules/HeaderV2ControlMolecule.vue";

const IHeaderV2Molecule = Vue.extend({
  props: {},
});

@Component({
  name: "HeaderV2Organism",
  components: { HeaderV2ControlMolecule, HeaderV2UserMolecule },
})
export default class HeaderV2Organism extends IHeaderV2Molecule {
  private componentClass = "HeaderV2Organism";
}
