
import Vue from "vue";
import Component from "vue-class-component";
import { faGrip, IconDefinition } from "@fortawesome/free-solid-svg-icons";

const IHeaderV2ControlBtnAtom = Vue.extend({
  props: {},
});

@Component
export default class HeaderV2ControlBtnAtom extends IHeaderV2ControlBtnAtom {
  private componentClass = "HeaderV2ControlBtnAtom";
  private thIcon: IconDefinition = faGrip;

  get currentRoute() {
    return this.$route.path;
  }
}
