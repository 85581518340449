
import Vue from "vue";
import Component from "vue-class-component";

const IHeaderV2UserTitleAtom = Vue.extend({
  name: "HeaderV2UserTitleAtom",
  props: {
    data: {
      required: true,
      type: String,
    },
  },
});

@Component
export default class HeaderV2UserTitleAtom extends IHeaderV2UserTitleAtom {
  private componentClass = "HeaderV2UserTitleAtom";

  get humanizedData(): string {
    return this.$props.data
      .split(" ")
      .map((item: string, i: number): string => {
        if (i === 0) {
          return this.dotText(item);
        }

        return item
          .split("")
          .slice(0, 1)
          .map((item): string => {
            return item.toUpperCase();
          })
          .join("");
      })
      .join(" ");
  }

  dotText(text: string): string {
    return text
      .split("")
      .map((item, i: number): string => {
        if (i === 0) {
          return item.toUpperCase();
        } else {
          return item;
        }
      })
      .join("");
  }
}
