
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import VueI18n from "vue-i18n";
import LocaleMessages = VueI18n.LocaleMessages;

@Component({})
export default class HeaderV2UserDDItem extends Vue {
  @Prop() isAction?: boolean;
  @Prop() title?: string | LocaleMessages;
  @Prop() icon?: IconDefinition;
  @Prop() to?: string;

  @Emit()
  onAction(event) {
    return event;
  }

  getSubmenuIsActive() {
    const pathArr = this.$route.path.split("/");
    const linkArr = this.to.split("/");

    if (this.$route.name == "Reports" && linkArr[1] == "reports") {
      return "active";
    } else {
      return pathArr[1] == linkArr[1] ? "active" : "";
    }
  }
}
