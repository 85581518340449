
import Vue from "vue";
import Component from "vue-class-component";
import EIcon from "@/components/ewa/general/Icon.vue";

const IHeaderV2Cog = Vue.extend({
  props: {},
});

@Component({
  components: {
    EIcon,
  },
})
export default class HeaderV2CogAtom extends IHeaderV2Cog {
  private componentClass = "HeaderV2CogAtom";
}
