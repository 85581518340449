
import Vue from "vue";
import Component from "vue-class-component";
import HeaderV2ControlBtnAtom from "@/components/header-v2/atoms/HeaderV2ControlBtnAtom.vue";
import HeaderV2LogoAtom from "@/components/header-v2/atoms/HeaderV2LogoAtom.vue";
import HeaderV2ProjectListAtom from "@/components/header-v2/atoms/HeaderV2ProjectListAtom.vue";
import ClickOutside from "vue-click-outside";
import { isUserCompanyHasParent } from "@/services/roles";

const IHeaderV2ControlMolecule = Vue.extend({
  props: {},
});
@Component({
  methods: { isUserCompanyHasParent },
  components: {
    HeaderV2LogoAtom,
    HeaderV2ControlBtnAtom,
    HeaderV2ProjectListAtom,
  },
  directives: {
    ClickOutside,
  },
})
export default class HeaderV2ControlMolecule extends IHeaderV2ControlMolecule {
  private componentClass = "HeaderV2ControlMolecule";
}
