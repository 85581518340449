
import Vue from "vue";
import Component from "vue-class-component";
import {
  faUserCircle,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";

const IHeaderV2UserAvatarAtom = Vue.extend({
  props: {
    src: {
      required: false,
      type: String,
    },
    borderRadius: {
      required: false,
      type: String,
      default: "25px",
    },
  },
});

@Component({
  name: "HeaderV2UserAvatarAtom",
})
export default class HeaderV2UserAvatarAtom extends IHeaderV2UserAvatarAtom {
  private componentClass = "HeaderV2UserAvatarAtom";
  private defaultIcon: IconDefinition = faUserCircle;

  get style(): string {
    return `
            background: ${this.src};
            border-radius: ${this.borderRadius}
        `;
  }
}
